import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'


const api = {
	_getJoinList: (isProd ? '' : '/api') + '/index.php?c=Statistics&a=userInfoList',
    _getStatistics: (isProd ? '' : '/api') + '/index.php?c=Statistics&a=detail',
	
}

export function _getJoinList(parameter) {
	return request({
		url: api._getJoinList,
		method: 'get',
		params: parameter
	})
}

/*白名单列表*/
export function _getStatistics(parameter) {
	return request({
		url: api._getStatistics,
		method: 'post',
		data: parameter
	})
}