<template>
    <page-header-wrapper>
        <template slot="extra"></template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper">
                <div class="sub_title">肥西供水集团用户满意度调查问卷</div>
            </div>
            <div class="stattistics_container">
                <div class="stattistics_container_main">
                    <div class="stattistics_container_main_list" v-for="(data,index) in listData" :key="data.id">
                        <div class="stattistics_container_main_list_title">{{ index+1 }}. {{ data.title }}</div>
                        <div class="stattistics_container_main_list_table"> 
                            <a-table :columns="columns" :data-source="data.options" :pagination="false" size="small" :bordered="false" rowKey="id">
                                <span slot="option" slot-scope="record">{{ record.option }}</span>
                                <span slot="total" slot-scope="record">{{ record.num }}</span>
                                <span slot="percent" slot-scope="record">{{ record.rate }}%</span>
                            </a-table>
                        </div>     
                    </div>
                </div>
            </div>
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment';
    import { Modal,message } from 'ant-design-vue';
    import { _getStatistics } from '@/api/statistics';
    import {
        STable,
        Ellipsis
    } from '@/components';
    const columns = [
        {
            title: '选项',
            scopedSlots: {
                customRender: 'option'
            },
            align:'center',
            width:'33%'
        },
        {
            title: '小计',
            dataIndex: '',
			scopedSlots:{
				customRender:'total'
			},
            align:'center',
            width:'33%'
        },
        {
            title: '百分比',
            dataIndex: '',
            scopedSlots: {
                customRender: 'percent'
            },
            align:'center',
            width:'33%'
        }
    ]

    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                listData:[],
            }
        },
		created(){
			this.getStatistics();
		},
        methods: {
            getStatistics(){
                _getStatistics({}).then(res=>{
                    //console.log(res);
                    if(res.code==200){
                        this.listData = res.data;
                    }
                })
            }
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            }
        }
    }
</script>


<style lang="less" scoped>
.sub_title{
    font-size: 20px;
    color: #252933;
    padding-left: 20px;
    margin-bottom: 20px;
    position: relative;
    font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Arial!important;
}
.sub_title::before{
    content:'';
    display: block;
    width: 4px;
    height: 18px;
    background: #252933;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
}
.stattistics_container{
    font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Arial!important;
    .stattistics_container_main{
        .stattistics_container_main_list{
            margin: 0 0 20px 0;
            .stattistics_container_main_list_title{
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #252933;
            }
            .stattistics_container_main_list_table{
                margin: 20px 25px 10px 25px;
            }
        }
    }
}
</style>